<template>
  <div class="content-wrapper">
    <div class="expenses main-content">

      <div class="page-header">
        <h1>Expenses Overview</h1>
      </div>

      <div class="view-toolbars">
        <ItemFilter class="fitler" placeholder="Enter Staff Name" @search="searchExpenses" @reset="resetFilter">
          <el-form class="search-form" :model="searchForm">
            <div class="row">
              <el-form-item label="Staff ID">
                <el-input v-model="searchForm.staff_id"></el-input>
              </el-form-item>
              <el-form-item label="Position">
                <el-input v-model="searchForm.position"></el-input>
              </el-form-item>
              <el-form-item label="Department">
                <el-input v-model="searchForm.department"></el-input>
              </el-form-item>
              <el-form-item label="Expense Content">
                <el-input v-model="searchForm.expense_content"></el-input>
              </el-form-item>
              <el-form-item label="Expense Type" style="max-width: 550px;">
                <el-select v-model="searchForm.expense_type" placeholder="Select the expense type">
                  <el-option
                    v-for="item in expenseTypeOptions"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value">
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="Status" style="max-width: 550px;">
                <el-select v-model="searchForm.status" placeholder="Select the status">
                  <el-option label="Pending" value="Pending"></el-option>
                  <el-option label="Accepted" value="Accept"></el-option>
                  <el-option label="Rejected" value="Reject"></el-option>
                </el-select>
              </el-form-item>
            </div>
          </el-form>
        </ItemFilter>

        <div class="tool">
          <router-link :to="{name:'CreateExpense'}">
            <button class="btn btn-primary">
              <i class="el-icon-plus"></i>
              New
            </button>
          </router-link>
        </div>
      </div>

      <div class="employee-list">
        <table class="table table-bordered">
          <thead>
            <tr>
              <th scope="col">Staff ID</th>
              <th scope="col">Staff Name</th>
              <th scope="col">Position</th>
              <th scope="col">Department</th>
              <th scope="col">Expense Content</th>
              <th scope="col">Expense Type</th>
              <th scope="col">Status</th>
              <th scope="col">Amount</th>
              <th scope="col">Date</th>
              <th scope="col">Create Date</th>
              <th scope="col">Action</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="expense in expenses">
              <th scope="row">{{expense.staff_id}}</th>
              <td>{{expense.employee_name}}</td>
              <td>{{expense.position}}</td>
              <td>{{expense.department}}</td>
              <td>{{expense.expense_content}}</td>
              <td>{{expense.expense_type}}</td>
              <td>{{expense.status}}</td>
              <td>${{expense.amount}}</td>
              <td>{{ formatDateTime(expense.date, 'YYYY-MM-DD') }}</td>
              <td>{{ formatDateTime(expense.create_date, 'YYYY-MM-DD HH:mm:ss')}}</td>
              <td class="action">
                <router-link :to="{name:'Expense', params: {id: expense.id}}">View</router-link>
                <a href="#" class="delete-link" @click="deleteExpense(expense.id)">Delete</a>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import ItemFilter from '@/components/ItemFilter.vue';
import Common from '@/lib/common';
import Financial from '@/lib/financial';

export default {
  name: 'ExpenseOverview',
  components: {
    ItemFilter,
  },
  data(){
    return {
      searchForm: {
        staff_id: '',
        position: '',
        department: '',
        expense_content: '',
        expense_type: '',
        status: '',
      },
      expenseTypes: [
        'Travel',
        'Hospitality',
        'Telephone Fee',
        'Freight',
        'Maintenance Fee',
        'Other',
      ],
      expenseTypeOptions:[
        {'label': 'Travel', 'value': 'Travel'},
        {'label': 'Hospitality', 'value': 'Hospitality'},
        {'label': 'Telephone Fee', 'value': 'Telephone Fee'},
        {'label': 'Freight', 'value': 'Freight'},
        {'label': 'Maintenance Fee', 'value': 'Maintenance Fee'},
        {'label': 'Other', 'value': 'Other'},
      ],
      originExpenses: [],
      expenses: [],
    }
  },
  mounted(){
    this.loadExpenses();
  },
  methods:{
    async loadExpenses(){
      const loginInfo = Common.getLoginInfo();
      try{
        const expenses = await Financial.loadAllExpense(this.apiUrl, loginInfo);
        this.originExpenses = expenses;
        this.expenses = expenses;
      }catch(err){
        console.log(err);
      }
    },
    resetFilter(){
      //Clean Filter
      for(let i = 0; i < Object.keys(this.searchForm).length; i++){
        const searchKey = Object.keys(this.searchForm)[i];
        this.searchForm[searchKey] = '';
      }
      this.expenses = this.originExpenses;
    },
    searchExpenses(textSearchValue){
      this.searchForm['employee_name'] = textSearchValue;
      const searchFilter = this.searchForm;
      const newExpenses = Common.filterItems(searchFilter, this.originExpenses);
      this.expenses = newExpenses;
    },
    formatDateTime(timestamp, format){
      const date = Common.formatDateTime(timestamp, format);
      return date;
    },
    async deleteExpense(expenseId){
      const loginInfo = Common.getLoginInfo();
      try{
        await Financial.deleteExpense(this.apiUrl, expenseId, loginInfo);
        const newExpenses = this.expenses.filter(function(item) {
          return item.id !== expenseId;
        });
        this.expenses = newExpenses;
      }catch(err){
        console.log(err);
      }
    },
  },
  computed: mapState({
   apiUrl: state => state.apiUrl,
 }),
}
</script>

<style lang="scss">
.view-toolbars{
  display: flex;
  position: relative;
  .fitler{
    flex: 1;
  }
  .el-icon-plus{
    font-size: 1.2em;
  }
}
.employee-list{
  .action{
    a{
      display: inline-block;
      margin-right: 10px;
    }
    .delete-link{
      color: red;
    }
  }
}
</style>
